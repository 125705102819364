@import "../../node_modules/normalize.css";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$esqo-public-web-primary: mat-palette($mat-indigo);
$esqo-public-web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$esqo-public-web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$esqo-public-web-theme: mat-light-theme((
  color: (
    primary: $esqo-public-web-primary,
    accent: $esqo-public-web-accent,
    warn: $esqo-public-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($esqo-public-web-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.removeToolsBtn{
  width: 30px;
  height: 30px;
  border: none;
  background-image: url("/assets/images/korzina.png");
  background-repeat: no-repeat;
  margin-bottom: -5px;
  margin-top: 3px;
  border-radius: 2px;
}


